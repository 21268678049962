import React from 'react';
import { Container } from 'theme-ui';
import SEO from '../components/seo';
import PrimaryLayout from '../components/layout/primary/primary';

const shippingPage: React.FC<{}> = () => (
	<PrimaryLayout>
		<SEO title="Indusfoods shipping policy" />
		<Container py="40px">
			<h1> Shipping Policy </h1>
			<p>All items @ IndusFoods.US are shipped from USA. Most orders are shipped in 1 to 3 business days of your purchase. Below are the guidelines of delivery time.</p>
			<p> <b>Standard Shipping:</b> 3 to 5 business days after shipping (may take up to 14 business days in case of special-order items). Items that are shipped FREE may take from 4 to 7 days from the time of shipment.</p>
			<p> <b> Expedited Shipping: </b>2 to 3 business days after shipping
			In case an item is out of stock or delay in shipping for any reason, we will notify.</p>

			<h1> More About Shipping </h1>
			<h2> Shipping Cost? </h2>
			<p>For orders less than $50, there is flat shipping of $9.99.</p>
			<p>To view actual shipping for an order or product, please add all the items to the cart and you can view the shipping estimate in the shopping cart page.</p>
			<p>Shipping costs depend on the options you choose, the size and weight of the item, and where the order is being shipped to. There may be additional shipping charges when shipping to Alaska, Hawaii or Puerto Rico. If you order from these states, we will reach out to you for any additional shipping charges.</p>

			<h2>FREE SHIPPING </h2>
			<p>You can also take advantage of FREE SHIPPING offered if the order total is above $50. Free Shipping may be available on select products or based on total order value from a specific store. Free Shipping is only applicable to initial order and not for exchange.
			Unless otherwise noted by a seller, FREE SHIPPING on orders is only available within contiguous USA excluding Alaska, Hawaii or Puerto Rico. If you order from the above states, we will reach out to you for the shipping charges.</p>

			<h2> Shipping Destination </h2>
			<p>At this time we offer shipping only to states within USA, Alaska, Hawaii and Puerto Rico.</p>

			<h2> Order Tracking </h2>
			<p>When your order is shipped, we will send you the shipping information or tracking number. This automatically sends an email to you which contains tracking information.</p>

			<h2> Undelivered Package </h2>
			<p>Please make sure that the delivery address you provide while placing your order is correct. In case a package is returned back to the seller undelivered, it is up to the buyers to notify IndusFoods.US to issue a refund. There is an $29.99 for undelivered package or returned items fees.</p>			
		</Container>		
	</PrimaryLayout>
);

export default shippingPage;
